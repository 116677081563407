.event-types-admin {
    max-width: 1000px;
    margin: auto;
    padding: 20px 0 !important;
    min-height: 100% !important;
}

.grid-container {
    min-width: 990px;
}
