#wrong-url-header {
    font-family: 'Poppins', serif !important;
    font-style: normal;
    font-weight: 700;
    font-size: 150px;
    color: #FFFFFF;
    margin: 20px 0 0 20px;
    z-index: 1;
}

#wrong-url-text {
    font-family: 'Poppins', serif !important;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    color: #FFFFFF;
    margin: 20px 0 0 20px;
    z-index: 1;
}
