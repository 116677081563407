.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px auto;
    min-height: 95vh;
    flex-direction: column;
}

.event-paper {
    border-radius: 6px !important;
    margin: 6px;
    min-width: 300px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.event-color {
    height: 10px;
    border-radius: 0 0 6px 6px;
    width: 100%;
}

.holder {
    display: flex;
    justify-content: space-between;
    min-width: 800px;
    padding: 40px;
}

.form {
    display: flex;
    flex-direction: column;
}

.form > :not(style){
    margin: 6px;
    width: 400px;
}

@media (min-width: 768px) and (max-width: 1024px) {

}

@media (max-width: 480px) {

    .holder {
        min-width: 90vw;
        padding: 10px 0;display: flex;
        justify-content: center;
        align-items: center;
        margin: 20px auto;
        min-height: 60vh;
        flex-direction: column;
    }

    .event-paper {
        border-radius: 6px !important;
        margin: 6px;
        min-width: 80vw;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }

    .form {
        display: flex;
        flex-direction: column;
        margin-top: 16px;
    }

    .form > :not(style){
        margin: 6px;
        width: 80vw;
    }

}
