.event-paper {
    border-radius: 6px;
    margin: 6px;
    min-width: 300px;
}

.event-color {
    height: 10px;
    border-radius: 6px 6px 0 0;
    width: 100%;
}

.event-details {
    padding: 18px;
    position: relative;
}

.event-button-holder {
    text-align: end;
    padding: 2px;
}

@media (min-width: 768px) and (max-width: 1024px) {

    .event-paper {
        border-radius: 6px;
        margin: 6px;
        min-width: 240px;
    }

    .event-details {
        padding: 10px;
        position: relative;
    }

    .event-button-holder {
        text-align: end;
        padding-inline: 10px;
    }

}

@media (max-width: 480px) {
    .event-paper {
        border-radius: 6px;
        margin: 6px;
        min-width: 80vw;
    }

    .event-details {
        padding: 10px;
    }

    .event-button-holder {
        text-align: end;
        padding-inline: 10px;
    }
    .event-color {
        height: 10px;
        border-radius: 6px 6px 0 0;
        width: 100%;
    }
}
