.login-background {
    background-image: url('../media/NewZingleyBG-min.jpg');
    position: relative;
    overflow: hidden;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-wrapper{
    box-sizing: border-box;
    height: 500px;
    width: 700px;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    margin: 5%;
    padding: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 65.1%) !important;
    filter: drop-shadow(0px 0px 150px rgba(0, 0, 0, 0.5));
    backdrop-filter: blur(22px);
    -moz-filter: blur(22px);
    -o-filter: blur(22px);
    -ms-filter: blur(22px);
    border-radius: 15px !important;
    border: 1px solid rgba(255,255,255,0.15);
    z-index: 0;
    -webkit-mask-image: linear-gradient(to bottom,black 70%,transparent 90%);
    /*mask-image: linear-gradient(to bottom,black 50%,transparent 80%);*/
}

.google-button {

}

.login-text {
    font-family: Poppins !important;
    color: #FFFFFF;
}

.login-buttons a{
    padding:4px;
    background: #cccccc;
    width: 100%;
    align-items: center;
    display: flex;
    border-radius: 4px;
    text-decoration: none;
    color: #000;
}

.login-buttons a:hover{
    background: #dcdcdc;
}

@media (max-width: 768px) {
    .login-background {
        background-image: url('../media/ZingleyNewBG_Mob-min.jpg');
    }
}
