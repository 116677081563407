.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    min-height: 100vh;
    flex-direction: column;
}

.event-types-events {
    box-sizing: border-box;
    width: 1150px;
    margin: 0 auto !important;
    padding: 20px 50px;
    min-height: 570px;
    max-height: 600px;
    position: absolute;
    top: 6%;
    border-radius: 15px;
}

.event-paper {
    border-radius: 6px !important;
    margin: 6px;
    min-width: 300px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    cursor: pointer;
}

.event-color {
    height: 10px;
    border-radius: 0 0 6px 6px;
    width: 100%;
}

.holder {
    display: flex;
    justify-content: space-between;
    min-width: 800px;
    padding: 40px;
}

.form {
    display: flex;
    flex-direction: column;
    color: #FFFFFF;
}

.form h2{
    display: flex;
    justify-content: start;
    align-items: center;
    font-family: 'Poppins', serif !important;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 42px;
}
.form > :not(style){
    margin: 6px;
    width: 460px;
}

.event-details-schedule {
    background: none !important;
    width: 390px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.additional-details{
    display: flex;
    align-items: center;
    margin: 5px 0;
    background: rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    width: fit-content !important;
    padding: 0 13px !important;
    height: 34px !important;
}

#details-long{
    width: 280px;
}

.additional-details h4{
    font-size: 16px;
    font-weight: 400;
}

.holder-calendar {
    min-height: 100% !important;
    max-height: 554px !important;
    overflow-y: auto !important;
    max-width: 540px !important;
}

.input-holder input{
    width: 430px !important;
    font-family: 'Poppins', serif !important;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #FFFFFF !important;
}

.input-holder input::placeholder{
    color: rgba(255, 255, 255, 0.5);
}

.input-holder p{
    text-align: start !important;
    padding: 4px 0;
}

.input-holder span{
    color: #FF0000;
    font-size: 14px !important;
}

#form-holder .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    color: #FFFFFF !important;
}

#form-holder .MuiFormControl-root {
    border: 1px solid rgba(255, 255, 255, 0.15) !important;
    border-radius: 4px !important;
}

#form-holder .MuiFormControl-root:hover {
    border: 1px solid rgba(255, 255, 255, 0.15) !important;
    border-radius: 4px !important;
}

#form-holder  .MuiOutlinedInput-notchedOutline {
    border: none !important;
}

.add-guests-button {
    width: fit-content !important;
    text-transform: none !important;
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 32px !important;
    color: #FFFFFF !important;
}
.add-guests-button span {
    text-decoration: underline !important;
}

.guest-input-holder input{
    width: 430px !important;
    font-family: 'Poppins', serif !important;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #FFFFFF !important;

}

.guest-input-holder input::placeholder{

    color: rgba(255, 255, 255, 0.5);
}

.guest-input-holder p{
    text-align: start !important;
    padding: 4px 0;
}

.guest-input-holder span{
    color: #FFFFFF;
    font-size: 14px !important;
}

.helper-text {
    padding-top: 4px;
    color: rgba(255, 255, 255, 0.5) !important;
}

.textarea-schedule {
    background: none !important;
    border: none !important;
    --Textarea-focusedHighlight: none !important;
    color: #FFFFFF !important;
}

.textarea-schedule::placeholder{
    color: rgba(255, 255, 255, 0.5);

}

.submit-button-holder {
    justify-content: right;
    width: 100%;
    display: flex;
    padding-top: 40px;
    padding-bottom: 20px;
    align-items: end;
}

.submit-button {
    background-color: #E5C518 !important;
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 32px !important;
    text-align: center !important;
    color: #131313 !important;
    text-transform: none !important;
    width: 180px !important;
    filter: drop-shadow(0 0 15px rgba(229, 197, 24, 0.5)) !important;

}

.event-confirmed {
    color: #FFFFFF;
}

.event-confirmed h3{
    font-family: 'Poppins', serif !important;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 42px;
}
.event-confirmed p{
    min-width: 460px !important;
    text-align: start;
    font-family: 'Poppins', serif !important;
    font-style: normal;
    font-weight: 200;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgba(255, 255, 255, 0.7);
}

.loader-box {
    min-width: 500px !important;
}

.temp-loader{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 150px;
    width: 470px;
}

.event-details-user-event{
    margin: 0 !important;
    padding: 0 !important;
}

.event-details-user-event div{
    margin: 12px 0 !important;
    padding: 0 13px ;
}

#scroll-holder {
    scroll-behavior: smooth;
}

@media (min-width: 768px) and (max-width: 1600px) {
    .loader-box {
        min-width: 400px !important;
    }

    .event-types-events {
        max-width: 1000px !important;
        margin: 5px auto !important;
        top: 3%;
    }
}

@media (min-width: 768px) and (max-width: 1600px) and (orientation : portrait) {


    .submit-button-holder {
        padding-bottom: 20px;
        padding-right: 20px;
    }

    .holder-calendar{
        flex-direction: row;
        padding-inline: 50px !important;
        max-height: 600px !important;
    }

    .guest-input-holder{
        width: 460px !important;

    }

    .loader-box {
        min-width: 300px !important;
    }

    .input-holder input{
        width: 430px !important;
    }

    .event-types-events {
        max-width: 90vw!important;
        margin: 5px auto !important;
        position: absolute;
        padding-inline: 10px !important;
        top: 10% !important;
    }

    .calendar-timeselect-holder{
        justify-content: center;
    }

    #details-long{
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        display: -webkit-box;
        -webkit-line-clamp: 3 !important;
        -webkit-box-orient: vertical;
        overflow: hidden;
        width: 160px;
    }
}

@media (max-width: 480px) {
    .holder {
        min-width: 90vw;
        padding: 10px 0;display: flex;
        justify-content: center;
        align-items: center;
        margin: 20px auto;
        min-height: 60vh;
        flex-direction: column;
    }



    .holder-calendar{
        position: relative;
        max-height: 100% !important;
    }

    .event-details-user-event {
        width: 100%;
        margin-top: 8px !important;
    }
    .event-details-user-event div{
        padding: 5px 15px;
        margin: 7px 0 !important;
    }

    .event-details-user-event {
        margin: 0 15px;
        padding: 0 !important;
        padding-bottom: 8px !important;
    }


    .event-types-events {
        max-width: 92vw;
        margin: auto;
        padding: 30px 15px !important;
        min-height: 95vh !important;
        height: auto !important;
        overflow: auto !important;
        top: 2%;
        overflow-x: auto;
        filter: none;
        backdrop-filter: none;
        -moz-filter: none;
        -o-filter: none;
        -ms-filter: none;
    }

    .event-paper {
        border-radius: 6px !important;
        margin: 6px;
        min-width: 80vw;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }

    .form {
        display: flex;
        flex-direction: column;
        margin-top: 16px;
        width: 80vw;
        padding-inline: 10px !important;
    }
    .form > :not(style){
        margin: 6px;
        width: 77vw;
    }

    .loader-box {
        width: 300px !important;
    }

    .MuiTextField-root{
        width: 77vw !important;
    }

    .submit-button{
        width: 100% !important;
    }

    .submit-button-holder{
        margin-bottom: 0 !important;
    }

    .temp-loader{
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 150px;
        width: 86vw;
    }

    .event-confirmed p{
        width: 77vw !important;
        min-width: 77vw !important;
    }
    .event-confirmed {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 12px;
    }

    #details-long{
        width: 77vw;
    }

    .holder-calendar{
        filter: none;
        backdrop-filter: none;
        -moz-filter: none;
        -o-filter: none;
        -ms-filter: none;
    }

    .submit-button-holder {
        padding-bottom: 0 !important;
        margin-top: 0 !important;
    }

    .header{
        margin-bottom: 20px!important;
    }

    .user-details {
        margin: 15px;
    }
}
