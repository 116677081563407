.buttons_holder {
    overflow: auto;
    max-height: 450px;
    display: flex;
    flex-direction: column;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.buttons_holder::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
}

.time-button {
    width: 150px;
    margin: 5px;
}

@media (min-width: 768px) and (max-width: 1200px) {

}

@media (max-width: 480px) {
    .time-button {
        width: 80vw;
        margin: 5px;
    }
}
