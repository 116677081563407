.background {
    /*background-image: url('../media/NewZingleyBG-min.jpg');*/
    position: relative;
    overflow: hidden;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

@media (max-width: 768px) {
    .background {
        /*background-image: url('../media/ZingleyNewBG_Mob-min.jpg');*/
    }
}
