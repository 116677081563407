.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px auto;
    min-height: 95vh;
    position: relative;
    overflow: hidden;
}

.event-types {
    box-sizing: border-box;
    width: 1150px;
    margin: auto;
    padding: 20px 50px;
    min-height: 570px;
    max-height: 600px;
    position: absolute;
    top: 16%;
    border-radius: 15px;
}

.loading-box{
    box-sizing: border-box;
    max-width: 1000px;
    margin: auto;
    padding: 20px 50px;
    position: relative;
    border-radius: 15px;
}

.blur-effect{
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    width: 100%;
    margin: auto;
    padding: 20px 50px;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 65.1%);
    filter: drop-shadow(0px 0px 150px rgba(0, 0, 0, 0.5));
    backdrop-filter: blur(9px);
    -moz-filter: blur(9px);
    -o-filter: blur(9px);
    -ms-filter: blur(9px);
    border-radius: 15px;
    border: 1px solid rgba(255,255,255,0.15);
    z-index: 0;
    -webkit-mask-image: linear-gradient(to bottom,black 50%,transparent 120%);
    mask-image: linear-gradient(to bottom,black 50%,transparent 120%);
}

.event-color {
    position: relative;
    height: 10px;
    border-radius: 6px 6px 0 0 !important;
    width: 100%;
    z-index: 1;
}

.event-paper{
    box-sizing: border-box;
    border-radius: 6px !important;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%);
    filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.08));
    backdrop-filter: blur(22px);
    -moz-filter: blur(22px);
    -o-filter: blur(22px);
    -ms-filter: blur(22px);
}

.event-types p {
    max-width: 300px;
    text-align: center;
}
.event-details-user {
    padding-inline: 15px;
    color: #FFFFFF;
}

.event-details-user p {
    max-width: 300px;
    text-align: start;
}

.event-details-user h3{
    font-family: Poppins;
    font-size: 24px;
    font-weight: 700;
    line-height: 42px;
    letter-spacing: 0px;
    text-align: left;

}

.event-details-user h4{
    font-family: 'Poppins', serif !important;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 42px;
    text-align: left;

}

/*.event-details-user p{
    font-family: Poppins;
    font-size: 12px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.15px;
    text-align: left;

}*/
/*.event-details-user span{
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    display: -webkit-box;
    -webkit-line-clamp: 5 !important;
    -webkit-box-orient: vertical;
    overflow: hidden;
    opacity: 70% !important;
}*/

.schedule-button-holder{
    text-align: end;
    margin: 0px 10px 15px 10px;
}

.schedule-button-holder button{
    color: #E5C518;
    font-size: 14px;
}

.header {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 65px 0 45px 0;
}

.header img {
    z-index: 1 !important;
}

.header h6 {
    font-family: Poppins;
    font-size: 20px;
    color: rgba(255, 255, 255, 0.6);
    font-weight: 200;
    width: 650px;
    text-align: center;
    line-height: 30px;
    z-index: 1;
    margin-top: 40px;
}

.grid-container::-webkit-scrollbar {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
}

.grid-container {
    max-height: 410px;
    overflow: auto;
    overflow-x: hidden;
    margin-bottom: 20px;
    min-width: 720px;
    max-width: 1200px;
}

.MuiGrid-root {
    z-index: 1;
}

.circle-1 {
    position: absolute;
    width: 1524px;
    height: 1525px;
    left: 1104px;
    top: 413px;

    background: radial-gradient(50% 50% at 50% 50%, rgba(255, 179, 0, 0.5) 0%, rgba(255, 179, 0, 0) 100%);
}

.circle-2 {
    position: absolute;
    width: 1755px;
    height: 1756px;
    left: calc(50% - 1755px/2 + 0.5px);
    top: -1118px;

    background: radial-gradient(50% 50% at 50% 50%, rgba(14, 175, 143, 0.5) 0%, rgba(14, 175, 143, 0) 100%);
}

.circle-3 {
    position: absolute;
    width: 1517px;
    height: 1517px;
    left: -636px;
    top: 320px;
    background: radial-gradient(50% 50% at 50% 50%, rgba(241, 4, 143, 0.5) 0%, rgba(241, 4, 143, 0) 87.5%);
}

.user-details{
    margin: 40px 40px 0 40px;
    position: relative;
    height: 60px;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(22px);
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.user-details img{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    position: absolute;
    left: 0;
    z-index: 1;
}

/*.user-details h5{
    font-family: Poppins;
    color: #FFF;
    font-size: 24px;
    font-weight: 400;
    z-index: 1;
}*/


.bottom-text {
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    line-height: 27px;
    padding-inline: 25%;
    color: #FFFFFF;
    font-weight: 200;
    position: relative;
}

.bottom-text a{
    text-decoration: underline;
    color: #FFFFFF !important;
    font-weight: 400;
}

.bottom-text-holder {
    margin-top: 45px !important;
}

@media (min-width: 768px) and (max-width: 1600px) {

    .wrapper {
        min-height: 85vh;
    }

    .event-types {
        max-width: 1000px !important;
        margin: 5px auto !important;
        top: 8% !important;
    }

    .circle-1 {
        position: absolute;
        width: 1524px;
        height: 1525px;
        left: 600px;
        top: 413px;
        background: radial-gradient(50% 50% at 50% 50%, rgba(255, 179, 0, 0.5) 0%, rgba(255, 179, 0, 0) 100%);
    }

    .user-details{
        margin: 16px;
    }

    .user-details h5{
        color: #FFF;
        font-size: 24px;
        font-weight: 400;
        z-index: 1;
    }

    .event-types p {
        max-width: 300px;
        text-align: center;
    }

    .event-details-user p {
        max-width: 300px;
        text-align: start;
    }

    .bottom-text-holder {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .bottom-text-holder h6 {
        width: 100%;
    }

}

@media (min-width: 768px) and (max-width: 1600px) and (orientation : portrait){

    .event-types {
        max-width: 90vw!important;
        margin: 5px auto !important;
        position: absolute;
        top: 15% !important;
    }

    .grid-container {
        min-width: 640px;
        max-width: 95vw;
    }

}

@media (max-width: 480px) {

    .wrapper {
        min-height: 80vh;
    }

    .event-types {
        max-width: 92vw;
        margin: auto;
        padding: 30px 15px !important;
        min-height: 95vh !important;
        top: 2% !important;
        overflow-x: auto !important;
        filter: none;
        backdrop-filter: none;
        -moz-filter: none;
        -o-filter: none;
        -ms-filter: none;
    }

    .holder-calendar {
        flex-direction: column !important;
        padding: 20px 0;
    }

    .blur-effect{
        filter: none;
        backdrop-filter: none;
        -moz-filter: none;
        -o-filter: none;
        -ms-filter: none;
    }

    .event-types p {
        max-width: 300px;
        text-align: center;
    }

    .event-details-user p {
        max-width: 300px;
        text-align: start;
    }

    .header {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin: 5px 0;
    }

    .user-details {
        height: 49px;
    }

    .user-details img {
        width: 49px;
        height: 49px;
    }

    .event-details-user div {
        padding: 0 !important;

    }

    .event-details-user {
    }

    .user-details h5 {
        font-size: 18px;
    }

    #description {
        display: none;
    }
    .header h6 {
        font-family: 'Poppins', serif !important;
        font-style: normal;
        font-weight: 200;
        font-size: 14px;
        line-height: 24px;
        width: 85%;
        text-align: center;
        margin-top: 0 !important;
        color: rgba(255, 255, 255, 0.7);
    }

    .bottom-text {
        width: 230px;
        font-family: 'Poppins', serif !important;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        color: #ACACAC;
    }

    .bottom-text a{
        text-decoration: underline;
        color: #FFFFFF !important;
        font-weight: 400;
    }

    .grid-container {
        justify-content: center;
        align-items: center;
        width: max-content !important;
        display: block !important;
        min-width: 83vw;
        max-height: 100% !important;
        margin: auto !important;
        margin-top: 20px !important;
    }
    .event-paper{
        margin: 2px auto !important;
        position: relative;
        min-width: 210px !important;
        width: 95% !important;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%);
        filter: none;
        backdrop-filter: none;
        -moz-filter: none;
        -o-filter: none;
        -ms-filter: none;
        padding-top: 6px !important;
    }

    .MuiGrid-item {
        padding-left: 0 !important;
    }

    .schedule-button-holder{
        position: absolute;
        width: 93%;
        height: 90px;
        top: -15px;
        z-index: 1 !important;
    }

    .schedule-button-holder button{
        position: relative;
        background: none;
        width: 100%;
        height: 100%;
        color: rgba(0,0,0,0) !important;
        z-index: 1 !important;
    }

    .circle-1 {
        position: absolute;
        width: 817px;
        height: 817px;
        left: 219px;
        top: 415px;
        background: radial-gradient(50% 50% at 50% 50%, rgba(14, 175, 143, 0.5) 0%, rgba(14, 175, 143, 0) 100%);
    }

    .circle-2 {
        position: absolute;
        width: 989px;
        height: 990px;
        left: calc(50% - 989px/2);
        top: -735px;
        background: radial-gradient(50% 50% at 50% 50%, rgba(241, 4, 143, 0.5) 0%, rgba(241, 4, 143, 0) 87.5%);
    }

    .circle-3 {
        position: absolute;
        width: 813px;
        height: 813px;
        left: -616px;
        top: 367px;
        background: radial-gradient(50% 50% at 50% 50%, rgba(255, 179, 0, 0.5) 0%, rgba(255, 179, 0, 0) 100%);
    }

    .loader-box{
        width: 90vw !important;
        margin: 0 !important;
        padding: 0!important;
        justify-content: center;
        align-items: center;
    }

    .details-holder {
        display: none;
    }

    .calendar-timeselect-holder{
        flex-direction: column;
    }

    .loading-box {
        width: 95vw !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .bottom-text-holder {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 0 auto;
        padding: 0;
    }

}
