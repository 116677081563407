.dashboard-main{
    margin: 0 50px;
}
.background-dashboard {
    position: relative;
    overflow: hidden;
    background-color: #f5f5f5;

    min-height: 100vh;
}

.background {
    background-image: url('../media/NewZingleyBG-min.jpg');
    position: relative;
    overflow: hidden;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

@media (max-width: 768px) {
    .background {
        background-image: url('../media/ZingleyNewBG_Mob-min.jpg');
    }
}
