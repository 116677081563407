@media (max-width: 480px){
    .time-button {
        width: 100px;
    }

    .time-button:disabled {
        width: 100px;
    }

    .small-time-button {
        width: 66px;
    }

    .select-time-button {
        min-width: 29px !important;
        width: 29px !important;
        padding: 0 !important;
    }

    .buttons_holder{
        margin: 7%;
        display: flex;
        flex-wrap: wrap;
        height: 150px;
        overflow-y: auto !important ;

        gap: 5px;
        align-content: flex-start;
    }

    .time-arrow-holder {
        flex-direction: row;
        display: flex;
    }

    .icon-holder{
        display: flex;
        justify-content: start;
        align-items: center;
        height: 160px;
    }

}

