.privacy-policy-background {
    /*background-image: url('../media/NewZingleyBG-min.jpg');*/
    position: relative;
    overflow: hidden;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.privacy-policy-wrapper {
    display: flex;
    align-items: center;
    height: 67vh;
    flex-direction: column;
    overflow: hidden;
}

.privacy-policy-text {
    color: #333;
    z-index: 999 !important;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: column;
    overflow: auto;
    height: 68vh;
}
.privacy-policy-text h1 {
    font-size: 3rem;
    margin-block: 2%;
}
.privacy-policy-text h2 {
    margin-top: 1.5%;
    font-size: 2rem;
    margin-bottom: 1.5%;
}
.privacy-policy-text h3 {
    font-size: 1.5rem;
    margin-bottom: 1%;
}
.privacy-policy-text p {
    font-size: 1rem;
    padding-left: 2%;
    margin-bottom: 1%;
}

.privacy-policy-text-privacy-policy {
    width: 90%;
    text-align: start;
}

.privacy-policy-event-types-events {
    margin-top: 50px;
    box-sizing: border-box;
    width: 80%;
    /*margin: 0 auto !important;*/
    padding: 120px 50px;
    min-height: 570px;
    max-height: 600px;
    position: absolute;
    top: 6%;
    border-radius: 15px;
    z-index: 1 !important;
}

.privacy-policy-blur-effect{
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    width: 100%;
    margin: auto;
    padding: 20px 50px;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 65.1%);
    filter: drop-shadow(0px 0px 150px rgba(0, 0, 0, 0.5));
    backdrop-filter: blur(22px);
    -moz-filter: blur(22px);
    -o-filter: blur(22px);
    -ms-filter: blur(22px);
    border-radius: 15px;
    border: 1px solid rgba(255,255,255,0.15);
    z-index: 1 !important;
    -webkit-mask-image: linear-gradient(to bottom,black 50%,transparent 80%);
    mask-image: linear-gradient(to bottom,black 10%,transparent 80%);
}

@media (max-width: 768px) {
    .privacy-policy-background {
        background-image: url('../media/ZingleyNewBG_Mob-min.jpg');
    }
}

