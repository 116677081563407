.timezone p{
    font-family: 'Poppins', serif !important;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 32px;
    display: flex;
    align-items: center;
    color: #000;
    text-align: start !important;
}

.timezone .select-wrapper{
    font-family: 'Poppins', serif !important;
    font-style: normal;
    font-weight: 200;
    font-size: 15px;
    line-height: 22px;
}

.timezone .select-wrapper div{
    background: none !important;
    color: #000;
}

#react-select-2-listbox {
    background: #FFFFFF !important;
}
