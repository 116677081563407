.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px auto;
    min-height: 95vh;
    flex-direction: column;
}

.MuiPickersSlideTransition-root {
    min-height: 260px !important;
}

.event-types-calendar {
    box-sizing: border-box;
    width: 1150px;
    margin: auto;
    padding: 20px 50px;
    min-height: 570px;
    max-height: 600px;
    position: absolute;
    top: 6%;
    border-radius: 15px;
}

.event-details-calendar {
    background: none !important;
    width: 340px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

#details-long{
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    display: -webkit-box;
    -webkit-line-clamp: 10 !important;
    -webkit-box-orient: vertical;
    overflow: hidden;
}


.event-color {
    height: 10px;
    border-radius: 0 0 6px 6px;
    width: 100%;
    position: absolute !important;
    left: 0;
    top: 0;
}

#react-select-2-listbox {
    /*overflow: hidden;*/
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.7) 100%) !important;
    backdrop-filter: blur(1px) !important;
    filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.15)) !important;
}

#react-select-2-listbox div {
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    padding-inline: 5px;
}

::-webkit-scrollbar {
    display: none;
}


.holder-calendar {
    position: relative;
    display: flex;
    justify-content: center;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0) 80%);
    filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.08));
    backdrop-filter: blur(22px);
    -moz-filter: blur(22px);
    -o-filter: blur(22px);
    -ms-filter: blur(22px);
    border-radius: 6px;
    width: fit-content !important;
    padding: 17px 24px 0 24px;
    margin: auto;
}


.circle {
    position: absolute;
    width: 3201px;
    height: 3201px;
    left: -636px;
    top: -1199px;
    background: radial-gradient(50% 50% at 50% 50%, rgba(241, 4, 143, 0.3) 0%, rgba(241, 4, 143, 0) 87.5%);
}

.calendar-timezone {
    flex-direction: column;
    color: #FFFFFF;
}

.calendar-timezone h3 {
    font-family: 'Poppins', serif !important;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #FFFFFF;
    margin: 20px 0 0 20px;
    display: flex;
    justify-content: start;
    align-items: center;
}

.MuiPickersDay-today{
    border-color: rgba(255, 255, 255, 0.3) !important;
}

.MuiPickersArrowSwitcher-root button{
    color: #E5C518;
}

.MuiDayCalendar-header span {
    font-family: 'Poppins', serif !important;
    color: #FFFFFF;
}

.MuiPickersDay-root {
    font-family: 'Poppins', serif !important;
    color: #FFFFFF !important;
    font-weight: 200 !important;
}

.Mui-disabled {
    color: #D9D9D9 !important;
    opacity: 30% !important;
}

.timezone{
    margin: 10px 20px;
}

.timezone p{
    font-family: 'Poppins', serif !important;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 32px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    text-align: start !important;
}
.timezone .tz-select-wrapper{
    font-family: 'Poppins', serif !important;
    font-style: normal;
    font-weight: 200;
    font-size: 14px;
    line-height: 22px;
}
.timezone .tz-select-wrapper div option:hover{
    background: red !important;
    cursor: pointer !important;
}

.timezone .tz-select-wrapper div{
    background: none !important;
    color: #FFFFFF;
    border-radius: 5px !important;
}

.timezone .tz-select-wrapper svg{
    color: #E5C518;
}
.css-13cymwt-control{
    border-color: #00000026 !important;
}

.css-1u9des2-indicatorSeparator {
    background-color: #00000026 !important;
}

.buttons_holder{
    margin-top: 12px !important;
    max-height: 420px !important;
    scroll-behavior: smooth;
    transition: scroll 0.3s ease-in-out;
    padding: 8px 12px;
}

.time-button {
    font-family: 'Poppins', serif !important;
    width: 150px;
    margin: 3px 0 !important;
    color: #FFFFFF !important;
    border-color: rgba(255, 255, 255, 0.15) !important;
    font-size: 14px !important;

}

.time-button:disabled {
    width: 150px;
    margin: 3px 0 !important;
    border: 1px solid rgba(255, 255, 255, 0.3) !important;
    font-family: 'Poppins', serif !important;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    color: #FFFFFF !important;
    opacity: 0.15;
}


.small-time-button{
    font-family: 'Poppins', serif !important;
    margin: 3px !important;
    color: #000 !important;
    width: 105px;
    background: #E5C518 !important;
    border: none !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    height: 34.5px !important;
    position: relative !important;
    z-index: 2 !important;
    filter: drop-shadow(0 0 6px rgba(229, 197, 24, 0.5)) !important;
}


.MuiPickersDay-today.Mui-selected{
    font-family: 'Poppins', serif !important;
    font-weight: 700 !important;
    padding-inline: 0 !important;
    color: #000 !important;
    background: #E5C518 !important;
    border: none !important;
    font-size: 12px !important;
}


.select-time-button{
    font-family: 'Poppins', serif !important;
    height: 34.5px !important;
    font-weight: 700 !important;
    min-width: 39px !important;
    width: 39px !important;
    padding-inline: 0 !important;
    margin: 3px !important;
    color: #000 !important;
    background: #E5C518 !important;
    border: none !important;
    font-size: 14px !important;
    filter: drop-shadow(0 0 6px rgba(229, 197, 24, 0.5)) !important;
}

.calendar-timeselect-holder{
    display: flex;
}

.checkbox-holder{
    display: flex;
    justify-content: start;
    align-items: center;
    margin-left: 10px;
    margin-top: 18px;
}

.checkbox-holder p{
    font-family: 'Poppins', serif !important;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: rgba(255, 255, 255, 0.8);
}


.full-availability {
    color: #E5C518 !important;
}

.not-full-availability {
    color: #FFF !important;
}
.Mui-disabled {
    color: #D9D9D9 !important;
}

.Mui-selected {
    font-weight: 700 !important;
    color: #000000 !important;
    background-color: #E5C518 !important;
    filter: drop-shadow(0 0 10px #E5C518) !important;
}

.MuiDayCalendar-monthContainer {
    padding: 15px !important;
}

@media (min-width: 768px) and (max-width: 1600px) {
    .event-types-calendar {
        width: 90vw;
        top: 5% !important;
    }

    .header {
        margin: 5px  !important;
    }

}

@media (min-width: 768px) and (max-width: 1600px) and (orientation : portrait) {

    .event-types-calendar {
        width: 90vw;
        top: 10% !important;
    }

    .holder-calendar{
        flex-direction: column;
    }

    .header{
        margin: 45px 0 !important;
    }

    .event-details-calendar{
        width: 100% !important;
    }

    .calendar-timeselect-holder{
        justify-content: center;
    }

    .small-time-button{
        width: 99px !important;
    }

    .select-time-button{
        width: 39px !important;
    }

}

@media (max-width: 480px) {

    .holder {
        min-width: 80vw;
        padding: 10px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 20px auto;
        min-height: 95vh;
        flex-direction: column;
    }

    .holder-calendar{
        filter: none;
        backdrop-filter: none;
        -moz-filter: none;
        -o-filter: none;
        -ms-filter: none;
    }

    .event-types-calendar {
        max-width: 92vw;
        margin: auto;
        padding: 30px 15px !important;
        min-height: 95vh !important;
        top: 2% !important;
        overflow-x: auto !important;
        filter: none;
        backdrop-filter: none;
        -moz-filter: none;
        -o-filter: none;
        -ms-filter: none;
    }



}


/* In your CSS file (e.g., styles.css) */
.animated-ellipsis::after {
    display: inline-block;
    content: '';
    animation: ellipsis steps(4, end) 1s infinite;
}

@keyframes ellipsis {
    0% {
        content: '';
    }
    33% {
        content: '.';
    }
    66% {
        content: '..';
    }
    100% {
        content: '...';
    }
}
